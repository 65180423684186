import React, { useEffect, useState } from 'react';
import { endpoints } from '@api/endpoints';
import API from '@api/index';
import AddFormModal from '@common/AddFormModal';
import { Common } from '@functions';
import { ButtonBase } from '@mui/material';
import { DrinkSessionRequest } from '@ts/Drink';
import { format, setHours, setMinutes, subDays } from 'date-fns';

import styles from '@pages/Life/style.scss';

type Props = {
  onUpdate: () => void;
};

type DrinkSessionValues = {
  date: string;
  clDomco: number;
  clAnnette: number;
  beerDomco: number;
};

const CountAdmin = ({ onUpdate }: Props) => {
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isAddCountModalOpen, setIsAddCountModalOpen] = useState<boolean>(false);
  const [isAddModalOpenFor, setIsAddModalOpenFor] = useState<string | null>(null);
  const [isFastDrinkModalOpen, setIsFastDrinkModalOpen] = useState<boolean>(false);

  useEffect(() => {
    loadTypes();
    loadCategories();
  }, []);

  const loadTypes = async () => {
    try {
      const data = await API.get(endpoints.countType.list);
      setTypes(data);
    } catch (err) {
      API.err(err);
    }
  };

  const loadCategories = async () => {
    try {
      const data = await API.get(endpoints.countCategory.list);
      setCategories(data);
    } catch (err) {
      API.err(err);
    }
  };

  const onAddItem = async (data: Record<string, unknown>) => {
    const values = { ...data };

    const period = {
      day: values.periodDay,
      month: values.periodMonth,
    };

    delete values.periodDay;
    delete values.periodMonth;

    try {
      await API.post(endpoints.count.add, { ...values, period });
      onUpdate();

      setIsAddModalOpenFor(null);
    } catch (error) {
      API.err(error);
    }
  };

  const onAddType = async (values: Record<string, unknown>) => {
    try {
      await API.post(endpoints.countType.add, { ...values });
      onUpdate();

      setIsAddModalOpenFor(null);
    } catch (error) {
      API.err(error);
    }
  };

  const onAddCategory = async (values: Record<string, unknown>) => {
    try {
      await API.post(endpoints.countCategory.add, { ...values });
      onUpdate();

      setIsAddModalOpenFor(null);
    } catch (error) {
      API.err(error);
    }
  };

  const onSaveDrinkSession = async (values: DrinkSessionValues) => {
    const dataToSend: Partial<DrinkSessionRequest> = {
      state: 'FINISHED',
      date: format(setMinutes(setHours(new Date(values.date), 19), 0), 'yyyy-MM-dd HH:mm:ss'),
      drinks: [
        ...Array.from({ length: values.clDomco }, () => ({
          type: '64820f0da3839509d0de1fab',
          player: '61685d07cff2c020c028580a',
        })),
        ...Array.from({ length: values.clAnnette }, () => ({
          type: '64820f0da3839509d0de1fab',
          player: '616863e246a0c523d5b4bfad',
        })),
        ...Array.from({ length: values.beerDomco }, () => ({
          type: '6495ca9ad9ce5b2e04654eca',
          player: '61685d07cff2c020c028580a',
        })),
      ],
      isLive: false,
      players: values.clAnnette
        ? ['61685d07cff2c020c028580a', '616863e246a0c523d5b4bfad']
        : ['61685d07cff2c020c028580a'],
      season: Common.getYear(),
    };

    try {
      await API.post(endpoints.drink.session.add, dataToSend);

      setIsFastDrinkModalOpen(false);
    } catch (err) {
      API.err(err);
    }
  };

  return (
    <div className={styles.actions}>
      <ButtonBase
        classes={{ root: styles.adminButton }}
        onClick={() => setIsAddCountModalOpen(true)}
      >
        Add item
      </ButtonBase>
      <ButtonBase
        classes={{ root: styles.adminButton }}
        onClick={() => setIsAddModalOpenFor('type')}
      >
        Add type
      </ButtonBase>
      <ButtonBase
        classes={{ root: styles.adminButton }}
        onClick={() => setIsAddModalOpenFor('category')}
      >
        Add category
      </ButtonBase>
      <ButtonBase
        classes={{ root: styles.adminButton }}
        onClick={() => setIsFastDrinkModalOpen(true)}
      >
        Add session
      </ButtonBase>
      <AddFormModal
        open={isFastDrinkModalOpen}
        onClose={() => setIsFastDrinkModalOpen(false)}
        title={`Add fast session`}
        defaults={{
          date: format(new Date(), 'yyyy-MM-dd'),
          clDomco: 2,
          clAnnette: 1,
          beerDomco: 0,
        }}
        config={[
          {
            id: 'date',
            label: 'Date',
            type: 'multipicker',
            items: [
              ...Array.from({ length: 10 }, (_, i) => i).map((i) => {
                const date = subDays(new Date(), 9 - i + 2);
                return {
                  id: `day${i}`,
                  label: format(date, 'd.M'),
                  value: format(date, 'yyyy-MM-dd'),
                };
              }),
              {
                id: 'yesterday',
                label: 'Yesterday',
                value: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
              },
              {
                id: 'today',
                label: 'Today',
                value: format(new Date(), 'yyyy-MM-dd'),
              },
            ],
          },
          {
            id: 'clDomco',
            label: 'Cuba Libre Domčo',
            type: 'counter',
          },
          {
            id: 'clAnnette',
            label: 'Cuba Libre Annette',
            type: 'counter',
          },
          {
            id: 'beerDomco',
            label: 'Beer Domčo',
            type: 'counter',
          },
        ]}
        onSubmit={(values) => onSaveDrinkSession(values as DrinkSessionValues)}
      />
      <AddFormModal
        open={!!isAddModalOpenFor}
        onClose={() => setIsAddModalOpenFor(null)}
        title={`Add ${isAddModalOpenFor || 'item'}`}
        config={
          isAddModalOpenFor === 'category'
            ? [
                {
                  id: 'name',
                  label: 'Name',
                  required: true,
                },
                {
                  id: 'sequence',
                  label: 'Sequence',
                  type: 'number',
                  required: true,
                },
              ]
            : [
                {
                  id: 'name',
                  label: 'Name',
                  required: true,
                },
              ]
        }
        onSubmit={isAddModalOpenFor === 'type' ? onAddType : onAddCategory}
      />
      <AddFormModal
        open={isAddCountModalOpen}
        onClose={() => setIsAddCountModalOpen(false)}
        title={`Add item`}
        defaults={{
          type: 'TODO',
        }}
        config={[
          {
            id: 'type',
            label: 'Type',
            items: types,
            type: 'select',
            required: true,
          },
          {
            id: 'name',
            label: 'Name',
            required: true,
          },
          {
            id: 'category',
            label: 'Category',
            items: categories,
            required: true,
          },
          {
            id: 'sequence',
            label: 'Sequence',
            type: 'number',
            required: true,
          },
          {
            id: 'periodDay',
            label: 'Day',
          },
          {
            id: 'periodMonth',
            label: 'Month',
          },
          {
            id: 'todos',
            type: 'adder',
            label: 'Todo list',
          },
        ]}
        onSubmit={onAddItem}
      />
    </div>
  );
};

export default CountAdmin;
