import React, { Key, useEffect, useState } from 'react';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { endOfMonth, format, getMonth, isSameMonth, startOfDay, startOfMonth } from 'date-fns';
import { Fade } from '@mui/material';

import API from '@api/index';

import { endpoints } from '@api/endpoints';

import CountButton from './components/CountButton';
import CountAdmin from './components/CountAdmin';

import { Identifier, UnsafeAny } from '@ts/General';
import { Count, CountCheck } from '@ts/Count';

import styles from './style.scss';
import ExpectedCountButton from '@pages/Life/components/ExpectedCountButton';

type CountConfiguration = {
  category: string;
  items: Count[];
};

type Props = {
  month: Date;
  filter: (count: Count) => boolean;
  centered?: boolean;
};

const Life = ({ month, filter, centered }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setData] = useState<Count[]>([]);
  const [checks, setChecks] = useState<CountCheck[]>([]);

  useEffect(() => {
    /**
    const update = async () => {
      try {
        const resp = await API.post(endpoints.util.updateDbItems, {
          model: 'CountCategory',
          action: 'ADD_USER',
          value: '61685d07cff2c020c028580a',
        });
      } catch (err) {
        console.log(err);
      }
    };

    update(); **/
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const data = await API.post(endpoints.count.search);

      setData(filter ? data.filter(filter) : data);
      setIsLoading(false);
    } catch (error) {
      API.err(error);
    }
  };

  const loadChecksHistory = async () => {
    try {
      const data = await API.post(endpoints.countCheck.search, {
        since: format(startOfMonth(month), 'yyyy-MM-dd'),
        until: format(endOfMonth(month), 'yyyy-MM-dd'),
      });

      setChecks(data);
    } catch (error) {
      API.err(error);
    }
  };

  /** Adjust data counts for current month **/
  const data = allData.map((d) => {
    const checksFound = checks.filter((c) => c.count === d._id);

    return { ...d, checks: checksFound };
  });

  const selectedMonth = getMonth(month);

  useEffect(() => {
    loadData();
    loadChecksHistory();
  }, [selectedMonth]);

  const onCheck = async (item: Count) => {
    try {
      if (item.type.name === 'COUNTER' || !item.checks.length) {
        const date = isSameMonth(new Date(month), new Date())
          ? new Date()
          : startOfDay(endOfMonth(new Date(month)));

        await API.post(endpoints.countCheck.add, { count: item._id, date });
      } else {
        const lastCheck = item.checks[item.checks.length - 1];

        await API.del(endpoints.countCheck.remove(lastCheck._id), {
          count: item._id as Identifier,
        });
      }

      await loadChecksHistory();
    } catch (error) {
      API.err(error);
    }
  };

  const onCheckTodo = async (id: Identifier, todoId: Identifier, isDone: boolean) => {
    try {
      await API.post(endpoints.count.markTodo(id, todoId), { done: !isDone });
      await loadChecksHistory();
    } catch (error) {
      API.err(error);
    }
  };

  const calcPercentage = (items: UnsafeAny[]) => {
    const done = items.filter((i) => i.done).length;
    const all = items.length;

    return Math.round((done / all) * 100);
  };

  const renderGroup = (group: CountConfiguration) => {
    if (group.category === 'GOALS') {
      return group.items.map((item: Count) => (
        <div className={classNames(styles.group, styles.white)} key={item._id as unknown as string}>
          <CountButton
            item={{ ...item, type: { name: 'PERCENTAGE' } } as unknown as Count}
            counter={calcPercentage(item.todos)}
            onClick={() => true}
            dark
          ></CountButton>
          {item.todos.map((todo) => (
            <CountButton
              sm
              dark
              item={{ ...todo, type: { name: 'TODO2' } } as unknown as Count}
              key={todo._id as unknown as string}
              counter={todo.done ? 1 : 0}
              onClick={() => onCheckTodo(item._id, todo._id, todo.done)}
            ></CountButton>
          ))}
        </div>
      ));
    }

    return (
      <div className={classNames(styles.group)}>
        <div className={styles.title}>{group.category}</div>
        {group.items.map((item) =>
          item.expectedMonthlyCount ? (
            <ExpectedCountButton
              key={item._id as unknown as Key}
              item={item}
              onClick={() => onCheck(item)}
            />
          ) : (
            <CountButton
              key={item._id as unknown as Key}
              item={item}
              counter={item.checks?.length}
              onClick={() => onCheck(item)}
            />
          ),
        )}
      </div>
    );
  };

  const parseData = (): CountConfiguration[] => {
    const config: CountConfiguration[] = [];

    const groupedData = groupBy(
      data.filter((d) => d.active),
      'category.name',
    );

    // eslint-disable-next-line guard-for-in
    for (const category in groupedData) {
      const items = groupedData[category];

      config.push({
        category,
        items,
      });
    }

    return config;
  };

  const config = parseData();

  return (
    <>
      <div className={styles.wrap}>
        <Fade in={!isLoading}>
          <div className={classNames(styles.content, centered && styles.center)}>
            {/** <Toolbar /> **/}
            <div className={styles.body}>
              {config.filter((c) => c.category !== 'GOALS').map(renderGroup)}
            </div>
            {config.filter((c) => c.category === 'GOALS').map(renderGroup)}
          </div>
        </Fade>
      </div>
      <CountAdmin onUpdate={() => loadData()} />
    </>
  );
};

export default Life;
