// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-tSU1G{display:flex;flex-wrap:wrap}.helper-1zpT5{position:absolute;top:20px;right:38px;text-transform:uppercase;text-align:left;letter-spacing:1px;font-weight:500}.secondary-NEAtj{opacity:.2}.active-dk\\+LE .insideBlock-lOD1M{background:#0af !important}.button-Tfk4E{padding:20px 5px 12px !important;flex-direction:column !important}.wrap-tSU1G:hover .hover-ZdWIN .insideBlock-lOD1M{background:#fff !important}.insideBlock-lOD1M{min-height:6px;border-radius:6px;min-width:100px;background:hsla(0,0%,100%,.2666666667);transition:.3s ease all}.info-5k55f{position:absolute;top:100%;transform:translate(0%, -50%);opacity:0;transition:.3s ease all}.wrap-tSU1G:hover .info-5k55f{opacity:.3;transform:translate(0, -50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-tSU1G",
	"helper": "helper-1zpT5",
	"secondary": "secondary-NEAtj",
	"active": "active-dk+LE",
	"insideBlock": "insideBlock-lOD1M",
	"button": "button-Tfk4E",
	"hover": "hover-ZdWIN",
	"info": "info-5k55f"
};
export default ___CSS_LOADER_EXPORT___;
