import React, { useState } from 'react';
import DarkMultiPicker from '@common/DarkMultipicker';
import Life from '@pages/Life';
import LifeTodo from '@pages/Life/Todo';
import { startOfMonth } from 'date-fns';

import MonthSelect from '../components/MonthSelect';

import styles from './style.scss';

const nav = [
  { label: 'Month', value: 'default' },
  { label: 'Todo', value: 'todo' },
  { label: 'Links', value: 'link' },
  { label: 'Exp', value: 'exp' },
];

const LifeLayout = () => {
  const [month, setMonth] = useState<Date>(startOfMonth(new Date()));
  const [page, setPage] = useState(localStorage.getItem('pick-life') || 'default');

  return (
    <div className={styles.wrap}>
      <MonthSelect
        onChange={(date) => setMonth(new Date(date))}
        minStart={startOfMonth(new Date('2024-07-01'))}
        value={month}
      />
      <div className={styles.body}>
        <div className={styles.head}>
          <DarkMultiPicker items={nav} identifier="life" onChange={setPage} />
        </div>
        {page === 'default' && (
          <Life
            month={month}
            filter={(todo) => !(todo.expectedMonthlyCount || todo.expectedYearlyCount)}
          />
        )}
        {page === 'todo' && <LifeTodo month={month} />}
        {page === 'link' && <div>Link</div>}
        {page === 'exp' && (
          <Life
            month={month}
            centered
            filter={(todo) => !!(todo.expectedMonthlyCount || todo.expectedYearlyCount)}
          />
        )}
      </div>
    </div>
  );
};

export default LifeLayout;
