import React from 'react';
import classNames from 'classnames';
import { ButtonBase } from '@mui/material';

import { Count } from '@ts/Count';

import styles from './style.scss';
import countButton from '@pages/Life/components/CountButton';
import { formatDate } from '@functions/time';

type Props = {
  item: Count;
  onClick: () => void;
};

const ExpectedCountButton = ({ item, onClick }: Props) => {
  switch (item.type.name) {
    case 'TODO':
    case 'COUNTER':
    case 'GOAL':
    case 'PERCENTAGE':
    case 'TODO2':
  }

  const checks = item.checks || [];

  // if (alert?.type === 'warning') className.push(styles.warning);
  // if (alert?.type === 'error') className.push(styles.error);

  return (
    <div className={styles.wrap}>
      {Array.from({ length: item.expectedMonthlyCount! }, (_, i) => {
        const check = checks[i];

        const withHover = i === checks.length;

        return (
          <ButtonBase
            key={i}
            classes={{
              root: classNames(
                styles.button,
                i < checks?.length && styles.active,
                withHover && styles.hover,
              ),
            }}
            onClick={onClick}
          >
            <div className={styles.insideBlock}></div>
            {check && (
              <div className={styles.info} style={{ transitionDelay: `${240 * i + 'ms'}` }}>
                {formatDate(check.createdAt, 'dayOnly')}.
              </div>
            )}
          </ButtonBase>
        );
      })}
      <div className={styles.helper}>
        {checks?.length}&nbsp;&nbsp;<span className={styles.secondary}>of</span>&nbsp;&nbsp;
        {item.expectedMonthlyCount}
      </div>
    </div>
  );
};

export default ExpectedCountButton;
