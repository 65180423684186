import React, { Key, KeyboardEvent, useEffect, useState } from 'react';
import { endpoints } from '@api/endpoints';
import API from '@api/index';
import DarkTextField from '@common/DarkTextField';
import Loading from '@common/Loading';
import { Hooks } from '@functions';
import { ButtonBase, Collapse, Fade, Icon, Tooltip } from '@mui/material';
import { Identifier } from '@ts/General';
import { Todo } from '@ts/Todo';
import classNames from 'classnames';
import { endOfMonth, format, subMonths } from 'date-fns';

import styles from './style.scss';

type TodoUpdateValues = {
  description: string;
  category: string;
};

type Props = {
  month: Date;
};

const LifeTodo = ({ month }: Props) => {
  const [todo, setTodo] = useState('');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(12);
  const [loading, setLoading] = useState(false);
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [values, setValues] = useState<TodoUpdateValues>({
    category: '66df09d6b703aeaa14359015',
    description: '',
  });
  const [mode, setMode] = useState('add');

  const [isLoading, setIsLoading] = useState(true);
  const [allData, setData] = useState<Todo[]>([]);

  useEffect(() => {
    loadData();
  }, [month]);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const data = await API.post(endpoints.todo.search, {
        since: format(subMonths(month, 1), 'yyyy-MM-dd'),
        until: format(endOfMonth(month), 'yyyy-MM-dd'),
      });

      setData(data);
      setIsLoading(false);
    } catch (error) {
      API.err(error);
    }
  };

  const onResetSearch = () => {
    setSearch('');
    setMode('add');
    setLimit(12);
  };

  const onSubmitTodo = async () => {
    try {
      await API.post(endpoints.todo.add, {
        text: todo,
        ...values,
      });

      setTodo('');
      loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const onDoneTodo = async (id: Identifier) => {
    try {
      await API.post(endpoints.todo.done(id));
      loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const highlightSubmit = Hooks.useDelay(!!todo, 500) && !!todo;

  return (
    <Fade in>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.areaWrap}>
            <div className={styles.area}>
              <div className={styles.areaBody}>
                <DarkTextField
                  name="todo-name"
                  onChange={(e) => {
                    if (mode === 'add') {
                      setTodo(e.target.value);
                    } else {
                      setSearch(e.target.value);
                    }
                  }}
                  value={mode == 'add' ? todo : search}
                  placeholder="Enter your todo"
                  options={{
                    fullWidth: true,
                    autoFocus: true,
                  }}
                  onKeyPress={(e: KeyboardEvent) => {
                    if (e.charCode === 13 && todo.length > 1) {
                      onSubmitTodo();
                    }
                  }}
                />
                {mode === 'filter' ? (
                  <>
                    <Tooltip
                      title={filtersEnabled ? 'Disable extra filters' : 'Show extra filters'}
                    >
                      <ButtonBase
                        className={classNames(
                          styles.iconButton,
                          styles.filterButton,
                          filtersEnabled && styles.active,
                        )}
                        onClick={() => {
                          setFiltersEnabled(!filtersEnabled);
                          // setValues({ ...values, category: '' });
                        }}
                      >
                        <Icon className={styles.icon}>
                          {search?.length ? 'filter_list' : 'filter_list_off'}
                        </Icon>
                      </ButtonBase>
                    </Tooltip>
                    <ButtonBase
                      className={styles.iconButton}
                      disabled={loading || !search?.length}
                      onClick={() => onResetSearch()}
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        <Icon className={styles.icon}>{search?.length ? 'close' : 'search'}</Icon>
                      )}
                    </ButtonBase>
                  </>
                ) : (
                  <ButtonBase
                    className={classNames(styles.iconButton, highlightSubmit && styles.active)}
                    disabled={!todo?.length}
                    onClick={() => onSubmitTodo()}
                  >
                    <Icon className={styles.icon}>{'arrow_forward'}</Icon>
                  </ButtonBase>
                )}
              </div>
              <Collapse in={filtersEnabled}>
                <div className={styles.areaFilters}>
                  <div className={classNames(styles.country, values.category && styles.active)}>
                    <div className={styles.countryLabel}>Category</div>
                    <ButtonBase classes={{ root: styles.select }} onClick={() => true}>
                      <div className={styles.country}>{'Select'}</div>
                      <Icon>{values.category ? 'close' : 'arrow_drop_down'}</Icon>
                    </ButtonBase>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <Collapse in={!!allData.length}>
            <div className={styles.list}>
              {!!allData.length &&
                allData.map((item) => (
                  <div
                    className={classNames(styles.todo, item.status === 'DONE' && styles.active)}
                    key={item._id as unknown as Key}
                  >
                    <div className={styles.todoText}>{item.text}</div>
                    <ButtonBase
                      classes={{ root: styles.todoButton }}
                      onClick={() => onDoneTodo(item._id)}
                    >
                      <Icon>done</Icon>
                    </ButtonBase>
                  </div>
                ))}
            </div>
          </Collapse>
        </div>
      </div>
    </Fade>
  );
};

export default LifeTodo;
