import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { camelCase, upperCase } from 'lodash-es';

import API from '../../../../../api';

import TextField from '../../../../common/TextField';
import Checkbox from '../../../../common/Checkbox';
import Select from '../../../../common/Select';
import Button from '../Button';

import './style.css';

const Toolbar = ({ categories, update, type }) => {
  const [addCategoryOpen, setAddCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newCategoryLevel, setCategoryLevel] = useState('');
  const [newCategoryAsSub, setCategoryAsSub] = useState(false);
  const [newCategorySub, setCategorySub] = useState('ideas');

  const addCategory = async () => {
    const data = {
      id: camelCase(newCategory),
      level: newCategoryLevel,
      type,
    };

    if (newCategoryAsSub) {
      data.sub = newCategorySub;
    }

    try {
      await API.post('/vision/categories', data);
      update();
      setAddCategory(false);
      setNewCategory('');
      setCategoryLevel('');
      setCategoryAsSub(false);
    } catch (error) {
      API.err(error);
    }
  };

  const uploadImage = () => {
    alert('Not implemented');
  };

  return (
    <>
      <div className="vision-items-tools">
        <div className="vision-items-tool" onClick={() => setAddCategory(!addCategoryOpen)}>
          Add category
        </div>
        <div className="vision-items-tool">
          <input
            type="file"
            className="vision-drag-input"
            onChange={(e) => e.target.value && uploadImage(e)}
            multiple
          />
          <span>Upload image</span>
        </div>
      </div>
      {addCategoryOpen && (
        <div className="vision-add-category">
          <div className="vision-add-category-fields">
            <TextField
              onChange={(e) => setNewCategory(e.target.value)}
              value={newCategory}
              label="Category name"
              className="vision-add-category-field"
            />
            <TextField
              disabled
              value={camelCase(newCategory)}
              className="vision-add-category-field"
              label="Category generated id"
              onChange={() => true}
            />
            <TextField
              onChange={(e) => setCategoryLevel(e.target.value)}
              value={newCategoryLevel}
              label="Category level (1-5)"
              type="number"
              min={1}
              max={5}
              className="vision-add-category-field"
            />
            <Checkbox
              label="Create as sub-category"
              checked={newCategoryAsSub}
              onChange={(e) => setCategoryAsSub(e.target.checked)}
            />
            {newCategoryAsSub && (
              <Select
                value={newCategorySub}
                className="vision-tool-select"
                onChange={(e) => setCategorySub(e.target.value)}
              >
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {upperCase(cat.id)}
                  </option>
                ))}
              </Select>
            )}
          </div>
          <Button onClick={() => addCategory()} className="vision-add-category-button">
            Save category
          </Button>
        </div>
      )}
    </>
  );
};

Toolbar.propTypes = {
  update: PropTypes.func,
  categories: PropTypes.array,
  type: PropTypes.string,
};

export default Toolbar;
